<template>
    <div id="loginblock">
        <img src="/img/mat.png" height="80" alt="Apple Tree Portaal">
        <h1>Wachtwoord vergeten</h1>
        <template v-if="passwordResetSent">
            <p>Binnen enkele minuten ontvangt u een e-mail met daarin een persoonlijke link. Via deze link kunt u een nieuw wachtwoord opgeven.</p>
        </template>
        <template v-else>
            <p>Vul hier uw email in om je password te resetten.</p>
            <form @submit.prevent="submit">
                <div id="loginform" class="loginfield">
                    <label for="email">E-mailadres</label>
                    <input type="text" name="email" v-model="formData.email" />
                </div>
                <br>
                <button class="btn btn-success loginsubmit" type="submit" @click.prevent="forgotPassword">Reset wachtwoord</button>
            </form>
        </template>
        <a @click="$router.back()" class="btn btn-warning loginsubmit mt-2" v-if="!this.formData.token">
            Terug naar inloggen
        </a>
    </div>
</template>

<script>
    import loginservice from '@/services/contact/LoginService'

    export default {
        name: 'ContactPasswordForgotComponent',
        data() {
            return {
                formData: {
                    'email': ''
                },
                passwordResetSent: false
            }
        },
        methods: {
            forgotPassword() {
                loginservice.forgot(this.formData).then(response => {
                    this.$toast.success('Password reset aanvraag is voldaan.')
                    /*this.$router.push({
                        name: "contactAccount",
                        params: {
                            type: 'login'
                        }
                    })*/
                    this.passwordResetSent = true;
                }).catch(error => {
                    this.$toast.error('Er ging iets mis.')
                })
            },
        }
    }
</script>

<style lang="css" scoped>
    #loginblock {
        width: 100%;
        max-width: 450px;
        position: absolute;
        top: 50%;
        left: 50%;
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
    }

    #loginblock img {
        margin-bottom: 75px;
    }

    input[type=text],
    input[type=password] {
        background-color: transparant;
        height: 45px;
        width: 100%;
        padding: 0px 20px;
        border: 1px solid #898989;
        border-radius: 4px;
        max-width: 450px;
    }

    input:autofill {
        background-color: #fff !important;
    }

    .loginfield {
        position: relative;
    }

    .loginfield label {
        background-color: #fff;
        padding: 0px 10px;
        position: absolute;
        font-size: 12px;
        height: 20px;
        top: -10px;
        left: 10px;
    }

    .loginsubmit {
        height: 45px;
        width: 100%;
        border: 0px;
        max-width: 450px;
    }
</style>