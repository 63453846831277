<template>
   <div id="loginpage">
      <div class="row">
         <div class="col-lg-8 logincols">
            <ContactLoginComponent v-if="showComponent('login')" />
            <ContactPasswordForgotComponent v-if="showComponent('forgot')" />
            <ContactRegisterComponent v-if="showComponent('register') || showComponent('reset')" />
         </div>
         <div id="rightblock" class="col-lg-4 logincols">
            <div id="rightblock_content">
               <h3>Telefonisch contact</h3>
               <p>Heeft u vragen over het gebruik van Mijn Apple Tree? Wij zijn op werkdagen telefonisch bereikbaar van 09:00 tot 17:30 en staan voor u klaar met advies.
                  <br><br>Bel: 020 - 470 09 20 </p>
            </div><img style="height: 50vh" src="/img/loginimage.png" alt="Login Image">
         </div>
      </div>
   </div>
</template>
<script>
   import ContactLoginComponent from '@/components/account/ContactLoginComponent'
   import ContactRegisterComponent from '@/components/account/ContactRegisterComponent'
   import ContactPasswordForgotComponent from '@/components/account/ContactPasswordForgotComponent'

   export default {

      name: 'contactAccount',
      components: {
         ContactLoginComponent,
         ContactRegisterComponent,
         ContactPasswordForgotComponent
      }

      ,
      methods: {
         showComponent(componentName) {
            return componentName === this.$route.params.type;
         }
      }
   }
</script>
<style lang="css" scoped>
   .row {
      background-color: #fff;
   }

   #loginpage {
      background-color: aqua;
   }

   .logincols {
      height: 100vh;
      position: relative;
   }

   #loginblock,
   #rightblock_content {
      width: 100%;
      max-width: 450px;
      position: absolute;
      top: 50%;
      left: 50%;
      -ms-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
   }

   #rightblock_content {
      top: 30%;
      padding: 0px 40px;
   }

   #loginblock img {
      margin-bottom: 75px;
   }

   #rightblock img {
      position: absolute;
      right: 0px;
      bottom: 0px;
   }

   #rightblock {
      color: #4DA751;
      background-color: #E3F1DE;
      padding: 0px 50px;
      position: relative;
   }

   input[type=text],
   input[type=password] {
      background-color: transparant;
      height: 45px;
      width: 100%;
      padding: 0px 20px;
      border: 1px solid #898989;
      border-radius: 4px;
      max-width: 450px;
   }

   input:autofill {
      background-color: #fff !important;
   }

   #rightblock h3 {
      color: #4DA751;
   }

   .loginfield {
      position: relative;
   }

   .loginfield label {
      background-color: #fff;
      padding: 0px 10px;
      position: absolute;
      font-size: 12px;
      height: 20px;
      top: -10px;
      left: 10px;
   }

   .loginsubmit {
      background-color: #4DA751;
      height: 45px;
      width: 100%;
      border: 0px;
      max-width: 450px;
   }
</style>