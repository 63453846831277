<template>
   <div id="loginblock">
      <img src="/img/mat.png" height="80" alt="Apple Tree Portaal">

      <template v-if="this.$route.params.type == 'reset'"> 
         <h1>Wijzig wachtwoord</h1>
         <form @submit.prevent="submit">
            <div id="loginform" class="loginfield">
               <label for="email">E-mailadres</label>
               <input type="text" name="email" v-model="formData.email" disabled class="disabled" />
            </div>
            <br>
            <p>Uw wachtwoord moet bestaan uit minimaal 8 karakters en minstens 1 cijfer en 1 hoofdletter bevatten.</p>
            <div class="loginfield">
               <label for="password">Wachtwoord</label>
               <input type="password" name="password" v-model="formData.password"/>
            </div>
            <br>
            <div class="loginfield">
               <label for="password_confirmation">Wachtwoord controle</label>
               <input type="password" name="password_confirmation" v-model="formData.password_confirmation" />
            </div>
            <br>
            <button class="btn btn-success loginsubmit" type="submit" @click="register">Wijzig wachtwoord</button>
            <br>
            <br>
            Al geregistreerd? klik dan hier: <br>
            <router-link to="/account/login" class="btn btn-warning loginsubmit">Naar Login</router-link>
         </form>
      </template>
      <template v-else>
         <h1>Registreren</h1>
         <p>Registreer uw Apple Tree Portaal account.</p>
         <form @submit.prevent="submit">
            <div id="loginform" class="loginfield">
               <label for="email">E-mailadres</label>
               <input type="text" name="email" v-model="formData.email" />
            </div>
            <br>
            <p>Uw wachtwoord moet bestaan uit minimaal 8 karakters en minstens 1 cijfer en 1 hoofdletter bevatten.</p>
            <div class="loginfield">
               <label for="password">Wachtwoord</label>
               <input type="password" name="password" v-model="formData.password"/>
            </div>
            <br>
            <div class="loginfield">
               <label for="password_confirmation">Wachtwoord controle</label>
               <input type="password" name="password_confirmation" v-model="formData.password_confirmation" />
            </div>
            <br>
            <button class="btn btn-success loginsubmit" type="submit" @click="register">Registreren</button>
            <br>
            <br>
            Al geregistreerd? klik dan hier: <br>
            <router-link to="/account/login" class="btn btn-warning loginsubmit">Naar Login</router-link>
         </form>
      </template>
   </div>
</template>

<script>
   import loginservice from '@/services/contact/LoginService'
   import store from '@/store/contact'

   export default {
      name: 'ContactRegisterComponent',
      data() {
         return {
            formData: {
               'email': '',
               'password': '',
               'password_confirmation': '',
               'token': null
            }
         }
      },
      computed: {
         isLoggedIn: function () {
            return store.getters.isLoggedIn
         }
      },
      methods: {
         register() {
               if (this.formData.password == this.formData.password_confirmation){
                  if (this.formData.password.match("(?=.*\\d)(?=.*[a-z])(?=.*[A-Z]).{8,}")){
                     loginservice.reset(this.formData).then(response => {
                           this.$toast.success('Registratie is gelukt')
                           store.dispatch('userLogin', response.data)
                           // this.$toast.success('Login success')
                           if (response.data.dossierId) {
                              this.$router.push({
                                 name: "myDossier",
                                 params: {
                                    dossierId: response.data.dossierId,
                                    stage: 'quote'
                                 }
                              })
                           } else {
                              this.$router.push({
                              name: "myDossiers"
                              })
                           }
                     }).catch(error => {
                        this.$toast.error('Registratie failed')
                     })
                  } else {
                     this.$toast.error('Password moet 8 karakters lang zijn met minimaal een hoofdletter en cijfer.')
                  }
               } else {
                  this.$toast.error('Password komt niet overeen met de controle password.')
            } 
         }
      },
      mounted() {
         this.formData.token = this.$route.query.token
         this.formData.email = this.$route.query.email
         store.dispatch('userLogout');
      }
   }
</script>

<style lang="css" scoped>
   #loginblock {
      width: 100%;
      max-width: 450px;
      position: absolute;
      top: 50%;
      left: 50%;
      -ms-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      padding: 0 25px;
   }

   #loginblock img {
      margin-bottom: 75px;
   }

   input[type=text],
   input[type=password] {
      background-color: transparant;
      height: 45px;
      width: 100%;
      padding: 0px 20px;
      border: 1px solid #898989;
      border-radius: 4px;
      max-width: 450px;
   }

   input:autofill {
      background-color: #fff !important;
   }

   .loginfield {
      position: relative;
   }

   .loginfield label {
      background-color: #fff;
      padding: 0px 10px;
      position: absolute;
      font-size: 12px;
      height: 20px;
      top: -10px;
      left: 10px;
   }

   .loginsubmit {
      height: 45px;
      width: 100%;
      border: 0px;
      max-width: 450px;
   }

   .btn {
      line-height: 32px
   }

   .disabled {
      background-color: #d3d3d35e;
   }
</style>