<template>
  <div id="loginblock" v-if="!isLoggedIn">
    <img src="/img/mat.png" height="80" alt="Apple Tree Portaal">
    <h1>Inloggen</h1>
    <p>Log in op Mijn Apple Tree.</p>
    <form @submit.prevent="submit">
      <div id="loginform" class="loginfield">
        <label for="email">E-mailadres</label>
        <input type="text" name="email" v-model="formData.email" />
      </div>
      <br>
      <div class="loginfield">
        <label for="password">Wachtwoord</label>
        <input type="password" name="password" v-model="formData.password" />
      </div>
      <br>
      <button class="btn btn-success loginsubmit" type="submit" @click="login">Inloggen</button>
      <br>
      <br>
      <router-link to="/account/forgot" class="btn btn-warning loginsubmit">Wachtwoord vergeten</router-link>
    </form>
  </div>
  <div id="loginblock" v-if="isLoggedIn">
    <img src="/img/mat.png" height="80" alt="Apple Tree Portaal">
    <h1>Uitloggen</h1>
    <form @submit.prevent="submit">
      <button class="btn btn-warning" type="submit" @click="logout">Logout</button>
    </form>
  </div>
</template>

<script>
  import loginservice from '@/services/contact/LoginService'
  import store from '@/store/contact'

  export default {
    name: 'ContactLoginComponent',
    data() {
      return {
        formData: {
          'email': '',
          'password': ''
        }
      }
    },
    computed: {
      isLoggedIn: function () {
        return store.getters.isLoggedIn
      }
    },
    methods: {
      login() {
        
        loginservice.login(this.formData).then(response => {
          store.dispatch('userLogin', response.data)
          // this.$toast.success('Login success')
          if (response.data.dossierId) {
            this.$router.push({
              name: "myDossier",
              params: {
                dossierId: response.data.dossierId,
                stage: 'quote'
              }
            })
          } else {
            this.$router.push({
              name: "myDossiers"
            })
          }
        }).catch(error => {
          this.$toast.error('Fout bij inloggen probeer het nogmaals')
        })
      },
      logout() {
        loginservice.logout().then(response => {
          store.dispatch('userLogout')
          // this.$toast.success('Logout success')
        }).catch(error => {
          this.$toast.error('Fout bij uitloggen probeer het nogmaals')
        })
      }
    }
  }
</script>

<style lang="css" scoped>
  #loginblock {
    width: 100%;
    max-width: 450px;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    padding: 0 25px;
  }

  #loginblock img {
    margin-bottom: 75px;
  }

  input[type=text],
  input[type=password] {
    background-color: transparant;
    height: 45px;
    width: 100%;
    padding: 0px 20px;
    border: 1px solid #898989;
    border-radius: 4px;
    max-width: 450px;
  }

  input:autofill {
    background-color: #fff !important;
  }

  .loginfield {
    position: relative;
  }

  .loginfield label {
    background-color: #fff;
    padding: 0px 10px;
    position: absolute;
    font-size: 12px;
    height: 20px;
    top: -10px;
    left: 10px;
  }

  .loginsubmit {
    height: 45px;
    width: 100%;
    border: 0px;
    max-width: 450px;
  }
</style>